import React from 'react';

import SEO from '../components/seo';

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <h1>CETTE PAGE N'EXISTE PAS</h1>
    <p>Nos architectes n'ont pas encore conçu cette page.</p>
  </>
);

export default NotFoundPage;
